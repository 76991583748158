import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import isEqual from 'lodash.isequal'
import qs from 'qs'

import { QueryStringParams, arrayParam } from 'lib/query-helper'

import CheckboxRadio from 'components/CheckboxRadio'

import GET_BRAND_VALUES from 'modules/recommendations/components/BrandValuesButtonGroupForm/graphql/GetBrandValueOptions.graphql'
import { GetBrandValueOptions } from 'modules/recommendations/components/BrandValuesButtonGroupForm/graphql/__generated__/GetBrandValueOptions'

import styles from './BrandValuesFilter.module.css'

const BrandValuesFilter = () => {
  const router = useRouter()

  const { data } = useQuery<GetBrandValueOptions>(GET_BRAND_VALUES)

  const brandValueOptions =
    data?.brandValues.map(brandValue => {
      return {
        label: brandValue.name,
        value: brandValue.id
      }
    }) ?? []

  const options = [
    {
      label: 'Show all',
      value: 'all'
    },
    ...brandValueOptions
  ]

  const brandValues = arrayParam(router.query.topBrandValueIds) || ['all']

  const handleFilterChange = (id: string) => {
    let params
    const url = new URL(document.location.href, document.location.origin)

    if (id === 'all') {
      params = {
        topBrandValueIds: ['all']
      }
    } else {
      let newBrandValueIds = brandValues.includes(id)
        ? brandValues.filter(brandValueId => brandValueId !== id)
        : [...brandValues, id]

      // Remove 'all' from brandValues if present
      newBrandValueIds = newBrandValueIds.filter(brandValueId => brandValueId !== 'all')

      // If all brand values are selected, replace with 'all' option
      if (isEqual(newBrandValueIds.sort(), brandValueOptions.map(option => option.value).sort())) {
        newBrandValueIds = ['all']
      }

      params = {
        topBrandValueIds: newBrandValueIds
      }
    }

    const query: QueryStringParams = {
      ...router.query,
      topBrandValueIds: params.topBrandValueIds.includes('all') ? null : params.topBrandValueIds.join(',')
    }

    // Remove empty filters from query
    for (const [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        query[key] = value.join(',')
      }
      if (!query[key]) {
        delete query[key]
      }
    }
    // Remove page / pagination
    // If we're refiltering catalogs, we want to go back ot the start of the new results
    if (query.page) {
      delete query.page
    }
    // Remove productCategorySlug from query
    if (query.productCategorySlug) {
      delete query.productCategorySlug
    }
    // Remove superDepartmentSlug from query
    if (query.superDepartmentSlug) {
      delete query.superDepartmentSlug
    }

    const href = `${url.origin}${url.pathname}?${qs.stringify(query)}`.replace(/\?$/, '')
    router.push(href)
  }

  return (
    <div className={styles.filtersContainer}>
      <div>
        <p className={styles.filterHeading}>Brand Values</p>
        {options.map((option, index) => (
          <CheckboxRadio
            key={index}
            name={option.label}
            type="checkbox"
            onChange={() => handleFilterChange(option.value)}
            label={option.label}
            checked={brandValues.includes(option.value)}
            className={styles.field}
          />
        ))}
      </div>
    </div>
  )
}

export default BrandValuesFilter
