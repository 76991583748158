import { useQuery } from '@apollo/client'
import { sortBy, capitalize } from 'lodash'

import GET_ALL_PRODUCT_CATEGORY_TAXONOMY from './graphql/GetAllProductCategoryTaxonomy.graphql'
import {
  GetAllProductCategoryTaxonomy,
  GetAllProductCategoryTaxonomy_productCategoryTaxonomies,
  GetAllProductCategoryTaxonomy_productCategoryTaxonomies_departments
} from './graphql/__generated__/GetAllProductCategoryTaxonomy'

export type GenerateNestedArrayArguments = {
  children?: GetAllProductCategoryTaxonomy_productCategoryTaxonomies[]
} & Pick<
  GetAllProductCategoryTaxonomy_productCategoryTaxonomies,
  'id' | 'parentId' | 'name' | 'slug' | 'slugPath' | 'hasCatalogs' | 'hasSellers' | 'hasProducts' | 'departments'
>

export type ProductCategoryTaxonomyDataCheck = Pick<
  GetAllProductCategoryTaxonomy_productCategoryTaxonomies,
  'hasCatalogs' | 'hasSellers' | 'hasProducts'
>

const generateNestedArray = (data: GenerateNestedArrayArguments[]) => {
  const nestedMap: { [key: string]: GenerateNestedArrayArguments[] } = {}
  const nestedArray: GenerateNestedArrayArguments[] = []

  // Create a mapping of parentId to their respective objects
  data.forEach(item => {
    const parentId = item.parentId ?? 'superCategory'

    nestedMap[parentId] = nestedMap[parentId] || []
    nestedMap[parentId].push({ ...item, children: [] })
  })

  // Recursive function to build the nested structure
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildHierarchy = (parentId: string | 'superCategory', parentObject: any) => {
    if (nestedMap[parentId]) {
      nestedMap[parentId]
        .sort((a, b) => a.slug.localeCompare(b.slug))
        .forEach((childItem: GenerateNestedArrayArguments) => {
          const newObj = { ...childItem, children: [] }
          if (!parentObject.children) {
            parentObject.children = []
          }
          parentObject.children.push(newObj)
          buildHierarchy(childItem.id, newObj)
        })
    }
  }

  // Build the top-level hierarchy (where parentId is null)
  buildHierarchy('superCategory', { children: nestedArray })

  return nestedArray
}

export const useProductCategoryTaxonomyHooks = () => {
  const { loading: isProductCategoryTaxonomyloading, data: productCategoryTaxonomyData } =
    useQuery<GetAllProductCategoryTaxonomy>(GET_ALL_PRODUCT_CATEGORY_TAXONOMY)
  const nestedProductCategoriesData = generateNestedArray(productCategoryTaxonomyData?.productCategoryTaxonomies || [])

  const getNestedProductCategoriesOfDepartmentSlug = (departmentSlug: string) => {
    const hasDepartmentSlug = (departments: GetAllProductCategoryTaxonomy_productCategoryTaxonomies_departments[]) =>
      departments.some(dep => dep.slug === departmentSlug)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function filterNode(node: any) {
      if (!node.children || node.children.length === 0) {
        return hasDepartmentSlug(node.departments) ? node : null
      }

      const filteredChildren = node.children.map(filterNode).filter(Boolean)

      if (filteredChildren.length > 0 || hasDepartmentSlug(node.departments)) {
        return { ...node, children: filteredChildren }
      }

      return null
    }

    return sortBy(nestedProductCategoriesData.map(filterNode).filter(Boolean), val => val.name?.toLowerCase(), ['desc'])
  }

  const getProductCategoryNameFromSlug = (slug: string | string[]) => {
    const getCategoryFromList = (slug: string) => {
      const productCategory = productCategoryTaxonomyData?.productCategoryTaxonomies.find(
        productCategory => productCategory.slug === slug
      )
      return productCategory?.name ?? 'All Categories'
    }

    if (typeof slug === 'string') {
      return getCategoryFromList(slug)
    } else {
      return slug.map(slug => getCategoryFromList(slug))
    }
  }

  const getDepartmentNameFromSlug = (slug: string) => {
    const department = productCategoryTaxonomyData?.productCategoryTaxonomies
      .map(productCategory => productCategory.departments)
      .flat()
      .find(dep => dep.slug === slug)
    return capitalize(department?.name) ?? ''
  }

  return {
    isProductCategoryTaxonomyloading,
    productCategoryTaxonomyData,
    nestedProductCategoriesData,
    getNestedProductCategoriesOfDepartmentSlug,
    getProductCategoryNameFromSlug,
    getDepartmentNameFromSlug
  }
}
