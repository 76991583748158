import { useRouter } from 'next/router'

import { arrayParam, QueryStringParams } from 'lib/query-helper'
import {
  FilterGroup,
  sellersFromFilterGroup,
  shipsToFilterGroup,
  minimumOrderFilterGroup,
  freeShippingOverFilterGroup
} from 'modules/marketplace/search/components/SearchFilters/SearchFilters'
import CheckboxRadio from 'components/CheckboxRadio'

import styles from './CatalogFilter.module.css'

const CatalogFilter = () => {
  const router = useRouter()

  const filters = {
    sellersFrom: arrayParam(router.query.sellerCountries) || ['all'],
    shipsTo: arrayParam(router.query.shippingCountries) || ['all'],
    minimumTotalOrderValue: arrayParam(router.query.minimumTotalOrderValue) || ['all'],
    freeShippingOver: arrayParam(router.query.freeShippingOver) || []
  }

  const handleFilterChange = (filterGroup: FilterGroup, id: string) => {
    let params
    // If radio button with only a single option selectable
    if (!filterGroup.isMultiSelect) {
      params = {
        ...filters,
        [filterGroup.name]: [id]
      }
      // If checkboxes with multiple options selectable
    } else {
      const filterGroupValues = filters[filterGroup.name as keyof typeof filters]

      // Reset to all
      if (id === 'all' || (filterGroupValues.includes(id) && filterGroupValues.length === 1)) {
        // Special case for free shipping over, just remove the freeShippingOver value
        if (filterGroup.name === 'freeShippingOver' && id === 'true') {
          params = {
            ...filters,
            [filterGroup.name]: []
          }
        } else {
          params = {
            ...filters,
            [filterGroup.name]: ['all']
          }
        }

        // Option is one of many currently checked, unchecked it
      } else if (filterGroupValues.includes(id)) {
        params = {
          ...filters,
          [filterGroup.name]: filterGroupValues.filter(
            selectedOption => selectedOption != id && selectedOption != 'all'
          )
        }
        // Default - add option to existing selection
      } else {
        params = {
          ...filters,
          [filterGroup.name]: [...filterGroupValues.filter(selectedOption => selectedOption != 'all'), id]
        }
      }
    }

    const query: QueryStringParams = {
      ...router.query,
      sellerCountries: params.sellersFrom.includes('all') ? null : params.sellersFrom.join(','),
      shippingCountries: params.shipsTo.includes('all') ? null : params.shipsTo.join(','),
      minimumTotalOrderValue: params.minimumTotalOrderValue.includes('all') ? null : params.minimumTotalOrderValue,
      freeShippingOver: params.freeShippingOver.length > 0 ? params.freeShippingOver.join(',') : null
    }

    // Remove empty filters from query
    for (const [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        query[key] = value.join(',')
      }
      if (!query[key]) {
        delete query[key]
      }
    }
    // Remove page / pagination
    // If we're refiltering catalogs, we want to go back ot the start of the new results
    if (query.page) {
      delete query.page
    }

    router.push(
      {
        query
      },
      undefined,
      { shallow: true }
    )
  }

  return (
    <div className={styles.filtersContainer}>
      {FILTER_GROUPS.map(filterGroup => (
        <div className={styles.filterGroup} key={filterGroup.name}>
          <p className={styles.filterHeading}>{filterGroup.heading}</p>
          {filterGroup.options.map((option, index) => (
            <CheckboxRadio
              key={index}
              name={filterGroup.name}
              type={filterGroup.isMultiSelect ? 'checkbox' : 'radio'}
              onChange={() => handleFilterChange(filterGroup, option.value)}
              label={option.label}
              checked={filters[filterGroup.name as keyof typeof filters].includes(option.value)}
              className={styles.field}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

const FILTER_GROUPS = [sellersFromFilterGroup, shipsToFilterGroup, freeShippingOverFilterGroup, minimumOrderFilterGroup]

export default CatalogFilter
