import React from 'react'
import classNames from 'classnames/bind'

import Footer from '../Footer'
import HeaderNav, { HeaderNavProps } from 'modules/marketplace/header/containers/HeaderNav/HeaderNav'
import DepartmentsMenuBar from 'modules/marketplace/header/components/DepartmentsMenuBar'
import MarketingFooter from 'modules/website/components/MarketingFooter'
import styles from './BasePage.module.css'
import useIntercomBoot from 'components/Intercom/useIntercomBoot'

const cx = classNames.bind(styles)

type Props = {
  className?: string
  children?: React.ReactNode
  hasMarketingFooter?: boolean
  hasDepartmentsMenu?: boolean
  hasHeader?: boolean
  hasFooter?: boolean
  hasGuestBanner?: boolean
  isTinted?: boolean
  hasDarkBackground?: boolean
  isPreview?: boolean
  headerNavProps?: HeaderNavProps
  hideIntercom?: boolean
}

const BasePage = ({
  className,
  children,
  hasMarketingFooter,
  hasDepartmentsMenu = false,
  hasHeader = true,
  hasFooter = true,
  isTinted,
  hasDarkBackground,
  isPreview = false,
  headerNavProps,
  hideIntercom = false
}: Props) => {
  const { hideIntercom: hide } = useIntercomBoot()
  hide(hideIntercom)

  return (
    <div className={cx(styles.container, { isTinted, hasDarkBackground }, className)}>
      {hasHeader && <HeaderNav isPreview={isPreview} {...headerNavProps} />}
      <main className={styles.main}>
        {hasDepartmentsMenu && <DepartmentsMenuBar />}
        {children}
      </main>
      {hasMarketingFooter && <MarketingFooter />}
      {hasFooter && <Footer />}
    </div>
  )
}

export default BasePage
