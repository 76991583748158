import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import Link from 'next/link'

import { useUserSession } from 'hooks'
import ButtonLink from 'components/ButtonLink'
import GET_ACCOUNT_SIGNUP_STATE from './graphql/GetAccountSignupState.graphql'
import {
  GetAccountSignupState,
  GetAccountSignupState_currentAccount_accountable_Seller,
  GetAccountSignupState_currentAccount_accountable_Rep
  // GetAccountSignupState_currentAccount_accountable_Buyer
} from './graphql/__generated__/GetAccountSignupState'
import { RepKindEnum, UserSignupSourceEnum, AccountableTypeEnum } from '../../../../../../__generated__/globalTypes'

import styles from './FinishSignupButton.module.css'

const FinishSignupButton = () => {
  const { isGuest, isSeller, isRep, currentUser, accounts } = useUserSession()
  const { signupSource } = currentUser || {}
  const numAccounts = accounts?.length || 0

  const [checkAccountSignupState, { loading, data }] = useLazyQuery<GetAccountSignupState>(GET_ACCOUNT_SIGNUP_STATE)

  useEffect(() => {
    if (isGuest) return

    if (isSeller || isRep) checkAccountSignupState()
  }, [checkAccountSignupState, isGuest, isSeller, isRep])

  if (isGuest) return null

  const account = data?.currentAccount
  let seller, rep //, buyer

  if (account?.type === AccountableTypeEnum.SELLER) {
    seller = account.accountable as GetAccountSignupState_currentAccount_accountable_Seller
  }

  // TODO: check if buyer signup is incomplete
  //
  // if (account?.type == 'buyer') {
  //   buyer = account.accountable as GetAccountSignupState_currentAccount_accountable_Buyer
  // }

  if (account?.type === AccountableTypeEnum.REP) {
    rep = account.accountable as GetAccountSignupState_currentAccount_accountable_Rep
  }

  if (loading) return null

  return (
    <>
      {/* If the user had created some credentials, but has not actually ucreated the buyer/seller/rep account yet */}
      {numAccounts === 0 && (
        <>
          {(signupSource === UserSignupSourceEnum.BUYER || signupSource === UserSignupSourceEnum.BUYER_INVITE) && (
            <Link href="/create-account/buyer">
              <a>Finish Signup</a>
            </Link>
          )}

          {signupSource === UserSignupSourceEnum.SELLER && (
            <Link href="/create-account/wholesaler">
              <a>Finish Signup</a>
            </Link>
          )}

          {signupSource === UserSignupSourceEnum.REP && (
            <Link href="/create-account/sales-rep/preview">
              <a>Finish Signup</a>
            </Link>
          )}
        </>
      )}

      {isSeller &&
        seller &&
        !seller?.onboardingState?.isOnboardingComplete &&
        seller?.onboardingState?.nextOnboardingStepPath && (
          <ButtonLink
            label="Continue your Fieldfolio setup"
            href={seller?.onboardingState?.nextOnboardingStepPath}
            kind="primary"
            icon="arrow-right"
            iconPosition="right"
            iconSize={16}
            className={styles.continueSetupBtn}
          />
        )}

      {isRep && rep && rep.kind === RepKindEnum.INDEPENDENT && !rep.profileCompleted && (
        <ButtonLink
          label="Continue your Fieldfolio setup"
          href="/create-account/sales-rep/preview"
          kind="primary"
          icon="arrow-right"
          iconPosition="right"
          iconSize={16}
          className={styles.continueSetupBtn}
        />
      )}
    </>
  )
}

export default FinishSignupButton
