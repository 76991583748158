import { useQuery } from '@apollo/client'

import GET_SUPER_DEPARTMENTS from './graphql/GetSuperDepartments.graphql'
import { GetSuperDepartments } from './graphql/__generated__/GetSuperDepartments'

const useDepartmentsMenuBar = () => {
  const { loading: superDepartmentsLoading, data: superDepartmentsData } =
    useQuery<GetSuperDepartments>(GET_SUPER_DEPARTMENTS)

  return {
    superDepartmentsLoading,
    superDepartmentsData
  }
}

export default useDepartmentsMenuBar
