import { Fragment } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import capitalize from 'lodash.capitalize'
import classNames from 'classnames/bind'

import { arrayParam } from 'lib/query-helper'

import Icon from 'components/Icon'

import {
  GenerateNestedArrayArguments,
  ProductCategoryTaxonomyDataCheck,
  useProductCategoryTaxonomyHooks
} from 'hooks/useProductCategoryTaxonomyHooks'
import { ElasticsearchIndexEnum } from '../../../../../../__generated__/globalTypes'

import styles from './ProductCategoriesNavigation.module.css'
const cx = classNames.bind(styles)

export const extractBasePath = ({
  pathname,
  superDepartmentSlug = ''
}: {
  pathname: string
  superDepartmentSlug: string
}) => {
  const match = pathname.match(/^(\/shop\/(c|d)?\/)/)
  const shopPath = match ? match[1] : '/shop/'

  if (shopPath === '/shop/c/') {
    // for Product Category Page
    return shopPath
  } else {
    // for Departments Page
    return `${shopPath}${superDepartmentSlug}`
  }
}

type ProductCategoriesNavigationProps = {
  productCategoryMode?: ElasticsearchIndexEnum[]
}

const getLeafCategories = (category: GenerateNestedArrayArguments): GenerateNestedArrayArguments[] => {
  if (category.children == null || !Boolean(category.children?.length)) {
    return [category]
  }
  return category.children.flatMap(getLeafCategories)
}

// Function to check if a category or its children should be hidden based on the productCategoryMode array
// If ALL of the categories per productCategoryMode are empty, the category should be hidden
const shouldHideCategory = (category: GenerateNestedArrayArguments, productCategoryMode?: ElasticsearchIndexEnum[]) => {
  const leafCategories = getLeafCategories(category)
  return productCategoryMode?.every(indexEnum => {
    const property = `has${indexEnum.charAt(0).toUpperCase() + indexEnum.slice(1).toLowerCase()}`
    const categoryEmpty = category[property as keyof ProductCategoryTaxonomyDataCheck] === false
    const hasChildren = Boolean(category.children?.length)
    const allChildrenEmpty = leafCategories?.every(
      leaf => leaf[property as keyof ProductCategoryTaxonomyDataCheck] === false
    )
    const hasNoContent = (!hasChildren && categoryEmpty) || (hasChildren && allChildrenEmpty)

    return hasNoContent
  })
}

const ProductCategoriesNavigation = ({ productCategoryMode }: ProductCategoriesNavigationProps) => {
  const { isProductCategoryTaxonomyloading, nestedProductCategoriesData, getNestedProductCategoriesOfDepartmentSlug } =
    useProductCategoryTaxonomyHooks()
  const router = useRouter()
  const { pathname, query } = router
  const superDepartmentSlug = query.superDepartmentSlug as string

  const basePath = extractBasePath({
    pathname,
    superDepartmentSlug: superDepartmentSlug as string
  })
  const isProductCategoryPage = basePath === '/shop/c/'

  const categoryQuery: string[] = arrayParam(query.productCategorySlug) || []
  const queryString = new URLSearchParams(router.asPath.split('?')[1]).toString()

  const productCategories = superDepartmentSlug
    ? getNestedProductCategoriesOfDepartmentSlug(superDepartmentSlug)
    : nestedProductCategoriesData

  const productCategoriesFiltered = productCategories.filter(category => {
    return !shouldHideCategory(category, productCategoryMode)
  })

  const renderNestedCategories = (
    data: GenerateNestedArrayArguments[] | undefined,
    renderFunction: (category: GenerateNestedArrayArguments) => JSX.Element,
    keyProperty: string
  ) => {
    if (!Array.isArray(data)) {
      return null
    }

    return data.map(subCategory => {
      // Check if the current subCategory should be hidden
      if (shouldHideCategory(subCategory, productCategoryMode)) {
        return null
      }

      // Render the subCategory
      return (
        <Fragment key={subCategory.id}>
          <div className={styles.subCategoriesContainer}>
            {renderFunction(subCategory)}
            {(subCategory.children ?? []).length > 0 &&
              categoryQuery.includes(subCategory.slug) &&
              renderNestedCategories(subCategory.children, renderFunction, keyProperty)}
          </div>
        </Fragment>
      )
    })
  }

  const renderSubCategoryTitle = (category: GenerateNestedArrayArguments) => (
    <Link
      href={`
        ${basePath}${isProductCategoryPage ? '' : '/c/'}${category.slugPath?.join('/')}${
        queryString ? `?${queryString}` : ''
      }
                  `}>
      <a>
        <span
          className={cx(
            category?.slug && categoryQuery.includes(category?.slug) ? styles.selectedCategoryName : styles.categoryName
          )}>
          {category?.name}
        </span>
      </a>
    </Link>
  )

  const resetProductCategoryFilters = () => {
    if (superDepartmentSlug) {
      delete query.superDepartmentSlug
    }

    if (query.productCategorySlug) {
      delete query.productCategorySlug
    }

    router.push(
      {
        pathname: basePath,
        query
      },
      undefined,
      { shallow: true }
    )
  }

  if (isProductCategoryTaxonomyloading) return null

  return (
    <div className={styles.categoriesContainer}>
      <>
        {!isProductCategoryPage && categoryQuery.length === 0 && (
          <Link href="/shop/c/" passHref>
            <h4 className={styles.navigationHeading}>
              <span className={styles.allCategoriesLink}>
                <Icon kind="arrow-left" size={16} />
                <span>All Categories</span>
              </span>
            </h4>
          </Link>
        )}

        {categoryQuery.length === 0 ? (
          <h4 className={styles.navigationHeading}>{capitalize(superDepartmentSlug)} Categories</h4>
        ) : (
          <h4 className={styles.navigationHeading} onClick={() => resetProductCategoryFilters()}>
            <span className={styles.allCategoriesLink}>
              <Icon kind="arrow-left" size={16} />
              <span>All {capitalize(superDepartmentSlug)} Categories</span>
            </span>
          </h4>
        )}

        {productCategoriesFiltered.map(category => (
          <Fragment key={category.id}>
            {categoryQuery.length === 0 || categoryQuery.includes(category.slug) ? (
              <div>
                <Link
                  href={`${basePath}${isProductCategoryPage ? '' : '/c/'}${category.slugPath?.join('/')}${
                    queryString ? `?${queryString}` : ''
                  }
                  `}>
                  <a>
                    <span
                      className={cx(
                        categoryQuery.length > 0 && categoryQuery.includes(category.slug)
                          ? styles.selectedCategoryName
                          : styles.categoryName
                      )}>
                      {category.name}
                    </span>
                  </a>
                </Link>

                {/* Show subcategories recursively */}
                {categoryQuery.length > 0 &&
                  categoryQuery.includes(category.slug) &&
                  renderNestedCategories(category.children, renderSubCategoryTitle, 'id')}
              </div>
            ) : null}
          </Fragment>
        ))}
      </>
    </div>
  )
}

export default ProductCategoriesNavigation
