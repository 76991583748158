import { useRouter } from 'next/router'

import CatalogFilter from '../CatalogFilter'
import DepartmentNavigation from '../DepartmentNavigation'
import ProductCategoriesNavigation from '../ProductCategoriesNavigation'
import BrandValuesFilter from 'modules/marketplace/search/components/BrandValuesFilter'

import { ElasticsearchIndexEnum } from '../../../../../../__generated__/globalTypes'

import styles from './ShopMainSidebar.module.css'

type ShopMainSidebarProps = {
  className?: string
  productCategoryMode?: ElasticsearchIndexEnum[]
  isShopExclusive?: boolean
}

const ShopMainSidebar = ({ className, productCategoryMode, isShopExclusive = false }: ShopMainSidebarProps) => {
  const router = useRouter()
  const { superDepartmentSlug, productCategorySlug } = router.query

  const showSuperDepartmentsFilter =
    !superDepartmentSlug && !productCategorySlug && ['/shop', '/shop/d'].includes(router.pathname)

  if (isShopExclusive && !router.pathname.includes('/shop')) return null

  return (
    <div className={className}>
      {showSuperDepartmentsFilter ? (
        <>
          <DepartmentNavigation />
          <div className={styles.divider} />
        </>
      ) : (
        <ProductCategoriesNavigation productCategoryMode={productCategoryMode} />
      )}
      <BrandValuesFilter />
      <CatalogFilter />
    </div>
  )
}

export default ShopMainSidebar
