import { FormEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react'
import {
  MenuProps,
  ControlProps,
  IndicatorSeparatorProps,
  OptionProps,
  SingleValue,
  MultiValue,
  InputActionMeta,
  MenuListProps,
  InputProps
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import debounce from 'debounce-promise'
import classnames from 'classnames'
import qs from 'qs'

import redirect from 'lib/universal-redirect'
import GlobalSearchCustomComponents from './GlobalSearchCustomComponents'
import useGlobalSearch from './useGlobalSearch'

import GET_SUGGESTED_SEARCH_RESULTS from './graphql/GetSuggestedSearchResults.graphql'
import {
  GetSuggestedSearchResults,
  GetSuggestedSearchResultsVariables
} from './graphql/__generated__/GetSuggestedSearchResults'
import { SearchSourceEnum } from '../../../../../../__generated__/globalTypes'

import styles from './GlobalSearch.module.css'

const FIELD_MAX_WIDTH = 640
const MAX_WIDTH_MOBILE = 420

export type GlobalSearchOption = {
  label: string
  value: string
  type?: 'sellers' | 'products' | 'brandValues' | 'productCategoryTaxonomies'
  slug?: string
  logoThumbUrl?: string
  catalogName?: string
  fullPath?: string
  slugPath?: string[]
  marketplacePath?: string
  searchResultUrl?: string
  isSponsoredProduct?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customStyles: any = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: (provided: ControlProps, { menuIsOpen }: any) => ({
    ...provided,
    maxWidth: FIELD_MAX_WIDTH,
    width: '100%',
    height: 40,
    borderBottom: menuIsOpen ? 0 : '1px solid var(--colorTan5)',
    borderBottomLeftRadius: menuIsOpen ? 0 : 4,
    borderBottomRightRadius: menuIsOpen ? 0 : 4,
    borderColor: menuIsOpen ? 'var(--colorTan9)' : 'var(--colorTan5)',
    boxShadow: menuIsOpen ? '0 -2px 0 4px rgba(228, 226, 221, 0.50)' : 'none',
    '&:hover': {
      border: menuIsOpen ? '1px solid var(--colorTan9)' : '1px solid var(--colorTan5)',
      borderBottom: menuIsOpen ? 0 : '1px solid var(--colorTan5)'
    }
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menu: (provided: MenuProps) => ({
    ...provided,
    maxWidth: FIELD_MAX_WIDTH,
    width: '100%',
    marginTop: 0,
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    border: '1px solid var(--colorTan9)',
    boxShadow: '2px 4px 0 2px rgba(228, 226, 221, 0.50), -2px 4px 0 2px rgba(228, 226, 221, 0.50)',
    borderTop: '1px solid var(--colorTan3)',
    zIndex: 100
  }),
  menuList: (provided: MenuListProps) => ({
    ...provided,
    height: '100%',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px'
    }
  }),
  input: (provided: InputProps) => ({
    ...provided,
    gridArea: '1/1/2/4'
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: OptionProps, { isFocused, data }: any) => ({
    ...provided,
    color: 'var(--colorBlack2)',
    overflowWrap: data.type === 'products' ? 'break-word' : 'normal',
    backgroundColor: isFocused ? 'var(--colorTan2)' : 'var(--colorWhite)',
    padding: data.type === 'products' && !data.searchResultUrl ? '8px 8px' : '8px 16px',
    ':first-child': {
      paddingLeft: '16px'
    },
    ':last-child': {
      paddingRight: '16px'
    },
    '&:hover': {
      backgroundColor: 'var(--colorTan2)',
      padding: data.type === 'products' && !data.searchResultUrl ? '8px 8px' : '8px 16px'
    },
    '&:hover:first-child': {
      paddingLeft: '16px'
    },
    '&:hover:last-child': {
      paddingRight: '16px'
    }
  }),
  indicatorSeparator: (provided: IndicatorSeparatorProps) => ({
    ...provided,
    display: 'none'
  })
}

export type DefaultOptionType = {
  label: string
  value: string
}
export const defaultOption = {
  label: '',
  value: ''
}

type GlobalSearchProps = {
  className?: string
}
const GlobalSearch = ({ className }: GlobalSearchProps) => {
  const router = useRouter()
  const [isClient, setIsClient] = useState(false)
  const [userQuery, setUserQuery] = useState<GlobalSearchOption>()
  const [inputValue, setInputValue] = useState<string>('')
  const [preventSubmitOnEnter, setPreventSubmitOnEnter] = useState<boolean>(true)
  const [windowWidth, setWindowWidth] = useState<number>(typeof window !== 'undefined' ? window.innerWidth : 0)
  const [placeholder, setPlaceholder] = useState<string>('Search for products or suppliers')

  const url = typeof document != 'undefined' && new URL(document.location.href, document.location.origin)
  const partsUrl = (url && qs.parse(url.search.replace('?', ''))) || {}

  const { getRecentSearchesFromCookie, storeRecentSearchesInLocalStorage, checkAndDeleteRecentSearches } =
    useGlobalSearch()
  const { clearIndicator, dropdownIndicator, customMenuList, customOption } = GlobalSearchCustomComponents()

  const [getSuggestedSearchResults] = useLazyQuery<GetSuggestedSearchResults, GetSuggestedSearchResultsVariables>(
    GET_SUGGESTED_SEARCH_RESULTS,
    {
      fetchPolicy: 'network-only'
    }
  )

  const loadFn = useMemo(
    () =>
      debounce(async (search: string) => {
        if (!search || search.trim() === '') {
          return []
        }

        const result = await getSuggestedSearchResults({
          variables: {
            searchTerm: search,
            source: SearchSourceEnum.ELASTICSEARCH
          }
        })

        const suggestedSearchResults = result.data?.suggestedSearchResults

        const products =
          suggestedSearchResults?.products?.map(res => {
            return {
              label: res.name,
              value: res.id,
              type: 'products',
              catalogName: res.catalog.name,
              marketplacePath: res.marketplacePath,
              logoThumbUrl: res.firstPicture?.gridPhotoUrl,
              isSponsoredProduct: Boolean(res.currentPromotionSlot?.id)
            }
          }) || []

        const sellers =
          suggestedSearchResults?.sellers?.map(res => {
            return {
              label: res.name,
              value: res.id,
              type: 'sellers',
              slug: res.slug,
              logoThumbUrl: res.logoThumbUrl,
              catalogName: res.marketplaceCatalogs.nodes[0].name
            }
          }) || []

        const brandValues =
          suggestedSearchResults?.brandValues?.map(res => {
            return {
              label: res.name,
              value: res.id,
              type: 'brandValues'
            }
          }) || []

        const productCategoryTaxonomies =
          suggestedSearchResults?.productCategoryTaxonomies?.map(res => {
            return {
              label: res.name,
              value: res.id,
              type: 'productCategoryTaxonomies',
              fullPath: res.fullPath,
              slugPath: res.slugPath
            }
          }) || []

        const options = [
          // Include defaultOption to serve as the first option preventing auto-highlight on first query result option
          defaultOption,
          ...products,
          ...sellers,
          ...brandValues,
          ...productCategoryTaxonomies
        ]

        return options
      }, 500),
    [getSuggestedSearchResults]
  )

  const handleOnInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    const { action } = actionMeta

    if (newValue) {
      setUserQuery({ label: newValue, value: newValue })
      setInputValue(newValue)
    } else {
      if (action === 'input-change') {
        setUserQuery(undefined)
        setInputValue('')
      }
    }
  }

  const handleOnChange = (selectedItem: SingleValue<GlobalSearchOption> | MultiValue<GlobalSearchOption>) => {
    setUserQuery(selectedItem as GlobalSearchOption)
    let redirectUrl = ''

    if (!selectedItem) {
      setInputValue('')
      return
    } else {
      setInputValue((selectedItem as GlobalSearchOption).label)
    }

    if ('type' in selectedItem) {
      partsUrl.q = (selectedItem as GlobalSearchOption).label

      if ('searchResultUrl' in selectedItem) {
        redirectUrl = selectedItem.searchResultUrl || ''
      } else {
        if (selectedItem.type == 'sellers') {
          redirectUrl = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${selectedItem.slug}`
        } else if (selectedItem.type == 'products') {
          redirectUrl = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}${selectedItem.marketplacePath}`
        } else if (selectedItem.type == 'brandValues') {
          redirectUrl = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/shop?topBrandValueIds=${selectedItem.value}`
        } else if (selectedItem.type == 'productCategoryTaxonomies') {
          redirectUrl = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/shop/c/${selectedItem.slugPath?.join('/')}`
        } else {
          // fallback, in-case cannot find matched 'type'
          redirectUrl = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/search?q=${selectedItem.label}`
        }
      }

      // Store search query after clicking on an option
      storeRecentSearchesInLocalStorage({
        query: selectedItem.type === 'productCategoryTaxonomies' ? selectedItem.fullPath ?? '' : selectedItem.label,
        type: selectedItem.type,
        url: redirectUrl
      })
    } else {
      if ('label' in selectedItem) {
        // If no 'type' value, redirect to SearchPage
        redirectUrl = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/search?q=${selectedItem.label}`
      } else return
    }

    redirect({
      href: redirectUrl
    })
  }

  const handleSubmit = (event: FormEvent) => {
    if (userQuery) {
      partsUrl.q = userQuery.value

      // Include product category slug(s) in search query
      if (router.query.productCategorySlug) {
        partsUrl.c =
          typeof router.query.productCategorySlug === 'string'
            ? router.query.productCategorySlug
            : router.query.productCategorySlug.join(',')
      }

      storeRecentSearchesInLocalStorage({
        query: userQuery.value,
        url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/search?${qs.stringify(partsUrl)}`
      })

      redirect({
        href: `/search?${qs.stringify(partsUrl)}`
      })
    }
    event.preventDefault()
  }

  const handleEnterOnSubmit = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!userQuery && (event.key === ' ' || event.key === 'Spacebar')) {
      // Prevent triggering onChange when space bar is pressed on empty input
      event.preventDefault()
    }

    if (
      event.key === 'ArrowDown' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    ) {
      // Allow use of Enter key, indicating user is navigating through the options
      setPreventSubmitOnEnter(false)
    }

    if (event.key === 'Enter') {
      if (preventSubmitOnEnter) {
        // Redirect to SearchPage using search query
        handleSubmit(event)
      }
    }
  }

  useEffect(() => {
    if (router.isReady && router.query.q) {
      setUserQuery({ label: String(router.query.q), value: String(router.query.q) })
      setInputValue(String(router.query.q))
    }
  }, [router.isReady, router.query.q])

  useEffect(() => {
    checkAndDeleteRecentSearches()
  }, [checkAndDeleteRecentSearches])

  // Get window size on resize
  useEffect(() => {
    setIsClient(true)

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Update placeholder based on screen width
  useEffect(() => {
    if (windowWidth < MAX_WIDTH_MOBILE) {
      setPlaceholder('Search')
    } else {
      setPlaceholder('Search for products or suppliers')
    }
  }, [windowWidth])

  // Prevents SSR related issues due to use of `window` object
  if (!isClient) return null

  return (
    <form action="" onSubmit={handleSubmit}>
      <AsyncSelect
        className={classnames({ [styles.select]: Boolean(userQuery) }, className)}
        styles={customStyles}
        components={{
          ClearIndicator: clearIndicator,
          DropdownIndicator: dropdownIndicator,
          MenuList: customMenuList,
          Option: customOption
        }}
        placeholder={placeholder}
        // Options and Value props
        loadOptions={loadFn}
        defaultOptions={getRecentSearchesFromCookie}
        value={userQuery}
        inputValue={inputValue}
        defaultValue={undefined}
        // Event props
        onInputChange={handleOnInputChange}
        onChange={handleOnChange}
        onKeyDown={handleEnterOnSubmit}
        // Other boolean props
        isClearable
      />
      <button className={styles.hidden} type="submit" />
    </form>
  )
}

export default GlobalSearch
